import { Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { Input } from "../components/InputFields";
import { FormLayout } from "../components/Layout";
import { addMessage } from "../state/reducers/appReducer";
import { fetchBaseOptions } from "../utils/fetch";
import { emailValidator, passwordValidator, repeatPasswordValidator } from "../utils/validators";

const SignupSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: emailValidator,
  password: passwordValidator,
  repeatPassword: repeatPasswordValidator,
});

const SignupPage = () => {
  const dispatch = useDispatch();
  return (
    <FormLayout seo={{ title: "Sign up" }}>
      <Formik
        initialValues={{ name: "", email: "", password: "", repeatPassword: "" }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          fetch("/api/user/create", {
            ...fetchBaseOptions,
            body: JSON.stringify(values),
          })
            .then(async res => {
              const j: IAPIResponse = await res.json();
              dispatch(addMessage(j));
              if (res.ok) {
                navigate("/login");
              }
            })
            .catch(err => console.error(err))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-2">
            <Field required={true} type="text" name="name" label="Name" component={Input} />
            <Field required={true} type="email" name="email" label="Email" component={Input} />
            <Field
              required={true}
              type="password"
              name="password"
              label="Password"
              component={Input}
            />
            <Field
              required={true}
              type="password"
              name="repeatPassword"
              label="Repeat password"
              component={Input}
            />
            <button
              className="w-full btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
      <p className="pt-4">
        Already have an account? <Link to="/login">Log in!</Link>
      </p>
    </FormLayout>
  );
};

export default SignupPage;
